<template>
    <header :class="['production-progress__header', {'remove-fixed': !isFixed}]">
        <div class="production-progress__back-labels-container">
            <div class="production-progress__back-button">
                <button @click="backEvent">
                    <ArrowLeftIcon />
                </button>
                <span>{{ backLabel }}</span>
            </div>

            <div class="production-progress__labels-container">
                <div v-if="orders.length === 1">
                    <div
                        :id="`${componentId}-appointment-header`"
                        class="production-progress__single-order text-ellipsis"
                    >
                        {{ orders[0].name }}
                    </div>
                    <b-tooltip
                        custom-class="tooltip-appointment-header"
                        :target="`${componentId}-appointment-header`"
                        triggers="hover"
                        placement="bottom"
                    >
                        {{ orders[0].name }}
                    </b-tooltip>
                </div>
                <MultipleOrderList :orders="orders" :removeOrder="removeOrder" @removeAppointment="removeAppointment" v-else/>
            </div>
        </div>

        <div
            class="production-progress__status-container text-ellipsis"
            :style="{ backgroundColor: `${this.color}20`, color: this.color }">
            {{ status }}
        </div>
    </header>
</template>

<script>
import MultipleOrderList from "./components/MultipleOrderList";
import ArrowLeftIcon from "@/assets/images/new-icons/arrow-left.svg";
import { BTooltip } from 'bootstrap-vue';

export default {
    components: {
        ArrowLeftIcon,
        MultipleOrderList,
        BTooltip
    },
    props: {
        componentId: {
            type: String,
            default: '',
        },
        backLabel: {
            type: String,
            default: ''
        },
        backEvent: {
            type: Function,
            default: () => {}
        },
        orders: {
            type: Array,
            default: []
        },
        removeOrder: {
            type: Function,
            default: () => {}
        },
        status: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        },
        isFixed: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        removeAppointment(appointmentId) {
            this.$emit("removeAppointment", appointmentId);
        },
    },
};
</script>

<style lang="scss">
.tooltip-appointment-header {
    z-index: 10000;
  //não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0;
  .tooltip-inner {
      padding: 8px;
      max-width: 516px;
      min-width: 1%;
      border-radius: 6px;
      overflow: hidden;
      background: #362F2B;
      padding: 7px 8px;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #fff
  }

  .arrow {
      &::before {
          border-top-color: #362F2B;
          border-bottom-color: #362F2B;
      }
  }
}

@media (max-width: 768px) and (min-width: 480px) {
    .tooltip-appointment-header {
        .tooltip-inner {
            max-width: 425px;
        }
    }
}

@media (max-width: 480px) {
    .tooltip-appointment-header {
        .tooltip-inner {
            max-width: 288px;
        }
    }
}
</style>

<style lang="scss" scoped>
.production-progress__header {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 49px;
    z-index: 12;
    box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.10);

    .text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
    }

    &.remove-fixed {
        top: initial !important;
        position: initial !important;
    }

    .production-progress__back-labels-container {
        display: flex;

        .production-progress__back-button {
            min-width: max-content;
            padding: 15px 24px;
            border: 1px solid #CFC4BE;
            border-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 16px;

            button {
                background: #fff;
                border-radius: 5px;
                border: none;
                padding: 0;
                outline: none;

                &:hover {
                    background: #FFEDE2;
                }

                &:active {
                    background: #FFDBC4;
                }

                svg {
                    height: 28px;
                    width: 28px;
                }
            }

            span {
                color: #4C4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
            }

        }

        .production-progress__labels-container {
            width: 100%;
            padding: 11px 29px;
            overflow: hidden;
            border: 1px solid #CFC4BE;
            border-left: 0;
            border-right: 0;

            .production-progress__single-order {
                color: #4C4541;
                font-size: 26px;
                font-weight: 600;
                line-height: 36px;
                text-align: center;
            }
        }
    }

    .production-progress__status-container {
        padding: 7px 9px 6px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        border-bottom: 1px solid #CFC4BE;
    }
}

@media (max-width:768px) and (min-width: 480px) {
    .production-progress__header {
        top: 86px;
        .production-progress__back-labels-container {
            .production-progress__back-button {
                padding: 6px 24px;

                button {
                    svg {
                        width: 46px;
                        height: 46px;
                    }
                }

                span {
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
    }
}


@media (max-width: 768px) and (min-width: 714px) {
    .production-progress__header {
        .production-progress__back-labels-container {
            .production-progress__back-button {
                padding: 6px 24px;
            }

            .production-progress__labels-container {
                padding: 11px 29px;
            }
        }
    }
}

@media (max-width: 714px) {
    .production-progress__header {
        .production-progress__back-labels-container {
            flex-direction: column;

            .production-progress__back-button {
                width: 100%;
                justify-content: start;
                border-right: 0;
            }

            .production-progress__labels-container {
                padding: 12px 29px;
                border: 0;
            }
        }

        .production-progress__status-container {
            padding: 6px 9px;
            border: 1px solid #CFC4BE;
            border-left: 0;
            border-right: 0;
        }
    }
}

@media (max-width: 480px) {
    .production-progress__header {
        top: 85px;
        .production-progress__back-labels-container {
            .production-progress__back-button {
                padding: 11px 16px;

                span {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .production-progress__labels-container {
            padding: 16px 29px !important;
            .production-progress__single-order {
                font-size: 18px !important;
                line-height: 26px !important;
            }
        }

        .production-progress__status-container {
            padding: 0 9px;
        }
    }
}
</style>
