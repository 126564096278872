<template>
  <div class="activity-card" @click="$emit('handleClick', active)">
    <Idle v-if="!active" :datetime="datetime" :title="title" :type="type" />
    <Finished
      v-else-if="type === 'finish'"
      :title="title"
      :type="type"
      :datetime="datetime"
    />
    <Active
      v-else
      :title="title"
      :type="type"
      :show-add-button="showAddButton"
      :custom-label="customActiveLabel"
      :timer="timer"
      :is-loading="isLoading"
      :date="datetime"
    />
  </div>
</template>

<script>
import Active from "./Active.vue";
import Idle from "./Idle.vue";
import Finished from "./Finished.vue";

export default {
  components: {
    Active,
    Idle,
    Finished,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    datetime: {
      type: String,
      default: null,
    },
    timer: {
      type: Object,
      default: () => ({
        hour: null,
        minute: null,
        second: null,
      }),
    },
    customActiveLabel: {
      type: String,
      default: null,
    },
    showAddButton: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    colors: {
      setup: "#78909c",
      stop: "#ef5350",
      start: "#ffa726",
      finish: "#66bb6a",
    },
  }),
};
</script>

<style lang="scss">
.activity-card {
  cursor: pointer;

  display: flex;
  flex: 1;
  min-height: 353px;
  height: 100%;

  box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
  border-radius: 24px;

  * {
    user-select: none;
  }
}

@media (max-width: 480px) {
  .activity-card {
    min-height: 250px;
  }
}
</style>
