<template>
  <div :class="['activity-card__idle', type]">
    <div class="icon">
      <component :is="icon" />
    </div>
    <div class="labels">
      <h1>{{ title }}</h1>
      <span v-show="datetime"> {{ datetime }}</span>
    </div>
  </div>
</template>

<script>
import SetupIcon from "@/assets/images/icons/setup-card-icon.svg";
import PauseIcon from "@/assets/images/icons/pause-card-icon.svg";
import PlayIcon from "@/assets/images/icons/play-card-icon.svg";
import FinishIcon from "@/assets/images/icons/finish-card-icon.svg";

const icons = {
  setup: SetupIcon,
  stop: PauseIcon,
  start: PlayIcon,
  finish: FinishIcon,
};
export default {
  components: {
    SetupIcon,
    PauseIcon,
    PlayIcon,
    FinishIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    datetime: {
      type: String,
      default: null,
    },
  },
  computed: {
    icon() {
      return icons[this.type];
    },
  },
};
</script>

<style lang="scss">
$setup: #78909c;
$stop: #ef5350;
$start: #ffa726;
$finish: #66bb6a;

$hover: #974900;

.activity-card__idle {
  box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 24px;

  padding: 0 20px;

  border: 1px solid #cfc4be;

  h1,
  span {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
  }

  h1 {
    font-size: 28px;
    line-height: 38px;
    color: #4c4541;
    margin-bottom: 0;
    text-align: center;
  }

  span {
    font-size: 18px;
    line-height: 26px;
    color: #998f8a;
  }

  .labels {
    display: flex;
    flex-direction: column;
    gap: 8.5px;
  }

  .icon {
    border-radius: 6px;
    padding: 16px;
    width: 112px;
    height: 112px;
  }

  &.setup > .icon {
    background-color: $setup;
    border: 1px solid $setup;
  }
  &.stop > .icon {
    background-color: $stop;
    border: 1px solid $stop;
  }
  &.start > .icon {
    background-color: $start;
    border: 1px solid $start;
  }
  &.finish > .icon {
    background-color: $finish;
    border: 1px solid $finish;
  }

  &:hover {
    background: #ffede2;
    h1,
    span {
      color: $hover;
    }
    .icon {
      background: #fff;
      border: 1px solid $hover;
    }
    path {
      fill: $hover;
    }
  }
  &:active {
    background: #ffdbc4;
  }
}

@media (max-width: 480px) {
  .activity-card__idle {
    .icon {
      padding: 0;
      width: 45px;
      height: 45px;
      svg,
      path {
        transform: scale(0.5);
        margin-left: -8.8px;
        margin-top: -7px;
      }
    }
    .labels {
      gap: 4px;
      h1 {
        font-size: 18px;
        line-height: 26px;
      }
      span {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>
