<template>
  <transition name="appointment">
    <div v-if="open" class="appointment-modal-container">
      <SelectModal
        v-if="selectModal.open"
        translation-base="ProductionProgress.SelectModal."
        :title="selectModal.title"
        :selected-id="selectModal.selectedId"
        :list="selectModal.data"
        @select-item="handleSelectModalClick"
        @close="selectModal.open = false"
      />

      <InputModal
        v-if="inputModal"
        :data="selectedFieldInputModal"
        @setQuantityValue="(context) => setValueInputModal(context)"
        @close="(title) => handleInputModal(title, 'close')"
      />

      <AppointmentHeader
        :component-id="'appointment-modal'"
        :back-label="headerBackLabel"
        :back-event="handleCloseModal"
        :orders="orderListDisplay"
        :status="headerStatus"
        :color="headerColor"
        :is-fixed="false"
        @removeAppointment="removeAppointment"
      />

      <div class="fields-container">
        <div class="fields-wrapper">
          <SetupAlert v-show="showSetupAlert()">
            {{ $t("ProductionProgress.AppointmentModal.SetupAlert") }}
          </SetupAlert>
          <div class="fields-list">
            <FieldCard :data="data['resource']" />
            <DateTimePicker
              v-model="datepicker"
              :disabled="!!data.date.disabled"
              type="datetime"
              position="left"
              :starts-with-time="true"
              :show-dates="showDates()"
              :block-dates="blockDates"
              :week-days="setWeekDays()"
              :month-names="setMonths()"
              :txt-cancel-button="$t('Datepicker.Cancel')"
              :txt-save-button="$t('Datepicker.Save')"
              :txt-time="$t('Datepicker.Time')"
              :txt-date="$t('Datepicker.Date')"
              @input="handleDatepickerChange"
            >
              <template v-slot:input>
                <FieldCard :data="data.date" />
              </template>
            </DateTimePicker>

            <FieldCard
              v-for="(field, index) in fields"
              :key="`field-${field}`"
              :class="[
                fields.length % 2 !== 0 && index === fields.length - 1
                  ? 'grid-full'
                  : '',
              ]"
              :data="data[field]"
            />
          </div>

          <div class="apm__button-c">
            <Button @click="confirmAppointment">
              {{ $t("ProductionProgress.AppointmentModal.ConfirmAppointment") }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { closeTooltips } from '@core/utils/utils';
import DateTimePicker from "components-code/date-time-picker/DatePickerFull.vue";
import AppointmentHeader from '@/views/pages/operator/components/AppointmentHeader';
import FieldCard from "@/views/pages/operator/components/AppointmentModal/components/FieldCard/FieldCard.vue";
import SetupAlert from "@/views/pages/operator/components/AppointmentModal/components/SetupAlert/SetupAlert.vue";
import Button from "@/views/pages/operator/components/AppointmentModal/components/Button/Button.vue";
import InputModal from "@/views/pages/operator/components/InputModal/InputModal.vue";
import SelectModal from "@/views/pages/operator/components/SelectModal/SelectModal.vue";
import moment from "moment";

export default {
  components: {
    AppointmentHeader,
    FieldCard,
    SetupAlert,
    Button,
    InputModal,
    SelectModal,
    DateTimePicker,
  },
  props: {
    appointmentType: {
      default: null,
    },
    allowChangeDate: {
      default: false,
    },
    defaultProducedQuantity: {
      default: 0,
    },
    isControlByHour: {
      default: null,
    },
    orderObject: {
      default: null,
    },
    showToast: {
      default: () => {},
    },
    formatDatetime: {
      default: () => {},
    },
    defaultDate: { type: Object, default: {} },
    allowChangeResource: {
      type: Boolean,
    },
    defaultResource: {
      type: Object,
    },
    title: {
      type: "Setup" | "Start" | "Partial" | "Continue" | "Stop" | "Finish",
      default: "Partial",
    },
    open: {
      type: Boolean,
      default: false,
    },
    resourcesList: {
      type: Array,
      default: [],
    },
    rejectionList: {
      type: Array,
      default: [],
    },
    statusList: {
      type: Array,
      default: [],
    },
    isMultipleOrders: {
      type: Boolean,
      default: false,
    },
    orderListDisplay: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    datepicker: new Date(),
    lang: this.$cookies.get('userInfo').language,
    triedToSave: false,
    data: {},
    inputModal: false,
    selectModal: {
      selectedId: null,
      open: false,
      title: "",
      data: [],
    },
    inputListModal: false,
    selectedFieldInputModal: {
      title: "",
      quantityProp: 0,
    },
  }),
  computed: {
    headerBackLabel() {
        return this.$t(`ProductionProgress.AppointmentModal.${ this.title }`)
    },
    headerStatus() {
        const { last_status, last_status_by_user } = this.orderObject;

        if (this.isMultipleOrders) return last_status;
        return this.isControlByHour ? last_status_by_user : last_status;
    },
    headerColor() {
        const {
            status_color,
            last_color_status_by_user,
            last_color_status
        } = this.orderObject;

        if (this.isMultipleOrders) return status_color;
        return this.isControlByHour ? last_color_status_by_user : last_color_status;
    },
    shouldFillDefaultValues() {
      const screensWithValues = ["Partial"];
      return screensWithValues.includes(this.title);
    },
    isSomePartialFieldFilled() {
      return ["produced", "rework", "rejection"].some(
        (field) => this.data[field].value
      );
    },
    fields() {
      return this.setFields();
    },
    form() {
      const fields = Object.keys(this.data);
      const payload = {};

      fields.forEach((field) => {
        payload[field] = this.data[field].value;
      });

      return payload;
    },
  },
  updated() {
    const rootElement = document.querySelector('.appointment-modal-container');
    rootElement && closeTooltips(rootElement);
  },
  watch: {
    open(isOpen) {
      if (isOpen) {
        this.closeAllModals();
        this.setData();
      }
    },
  },
  mounted() {
    this.$watch("$i18n.locale", function () {
      this.handleLanguageChange();
    });
  },
  methods: {
    headerBackEvent() {

    },
    removeAppointment(appointmentId) {
        this.$emit('removeAppointment', appointmentId);
    },
    showDates() {
      const currentYear = new Date().getFullYear();
      const firstDayPreviousYear = new Date(currentYear - 1, 0, 1);
      const today = new Date(new Date().setDate(new Date().getDate() + 1));

      return [firstDayPreviousYear, today];
    },
    blockDates(date) {
      const currentDate = moment(moment().format("YYYY-MM-DD"));
      const inspected = moment(moment(new Date(date)).format("YYYY-MM-DD"));

      return inspected > currentDate;
    },
    setWeekDays() {
      const weekdays = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      return weekdays.map((day) => ({
        name: this.$t(`Datepicker.Weekdays.Full.${day}`),
        short: this.$t(`Datepicker.Weekdays.Short.${day}`),
      }));
    },
    setMonths() {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return months.map((month) => ({
        name: this.$t(`Datepicker.Months.Full.${month}`),
        short: this.$t(`Datepicker.Months.Short.${month}`),
      }));
    },
    handleDatepickerChange(date) {
      this.data.date.value = this.formatDatetime(date);
    },
    handleLanguageChange() {
      this.lang = this.$i18n.locale;
      this.data.date.value = this.formatDatetime(this.datepicker);
    },
    getLastAppointment() {
      const order = this?.orderObject;
      return this.isControlByHour
        ? moment(order?.last_event_time_by_user).toDate()
        : moment(order?.last_event_time).toDate();
    },
    validateForm() {
      const screen = String(this.title).toLowerCase();
      // Definindo quais campos devem ser validados
      const initialFields = ["resource", "date"];
      const fields = {
        stop: ["status"],
        partial: this.data.rejection.value ? ["reason"] : [],
      };
      const fieldsWithQuantity = ["produced", "rework", "rejection"];

      const fieldsToValidate =
        screen in fields ? fields[screen] : initialFields;

      // Se qualquer um dos campos (produzido, retrabalho, refugo) estiver preenchido, permite envio
      if (screen === "partial" && !this.isMultipleOrders) {
        const filledAnyField = fieldsWithQuantity.some(
          (field) => !!this.data[field].value
        );

        if (!filledAnyField && !this.isControlByHour) {
          this.showErrorToast("quantityFields");
          return false;
        }
      }

      const emptyFields = fieldsToValidate.filter(
        (field) => !!this.data?.[field]?.alert
      );

      if (emptyFields.length) {
        this.showErrorToast("requiredFields");
        return false;
      }

      // Validação de datas
      const lastAppointment = moment(this.getLastAppointment());
      const currentTime = moment(new Date());
      const selectedDate = moment(this.datepicker);

      const current = currentTime.clone().startOf('minute');
      const selected = selectedDate.clone().startOf('minute');

      const isHigherThanCurrentTime = moment(selected).isAfter(current);
      if (isHigherThanCurrentTime) {
        this.showErrorToast("higherThanCurrentTime");
        return false;
      }

      // Setup permite data anterior ao último apontamento
      if (this.title === "Setup") return true;

      const isLowerThanLastAppointment =
        moment(selectedDate).isBefore(lastAppointment);

      if (!!lastAppointment && isLowerThanLastAppointment) {
        this.showErrorToast("lowerThanLastAppointment");
        return false;
      }

      return true;
    },
    showErrorToast(type) {
      const types = {
        requiredFields: {
          title: this.$t("ProductionProgress.Toasts.RequiredFields.Title"),
          icon: "AlertOutline",
          text: this.$t("ProductionProgress.Toasts.RequiredFields.Content"),
          variant: "alert",
        },
        quantityFields: {
          title: this.$t(
            "ProductionProgress.Toasts.QuantityFieldsRequired.Title"
          ),
          icon: "AlertOutline",
          text: this.$t(
            "ProductionProgress.Toasts.QuantityFieldsRequired.Content"
          ),
          variant: "alert",
        },
        higherThanCurrentTime: {
          title: this.$t(
            "ProductionProgress.Toasts.HigherThanCurrentTime.Title"
          ),
          text: this.$t(
            "ProductionProgress.Toasts.HigherThanCurrentTime.Content"
          ),
          variant: "danger",
        },
        lowerThanLastAppointment: {
          title: this.$t(
            "ProductionProgress.Toasts.LowerThanLastAppointment.Title"
          ),
          text: this.$t(
            "ProductionProgress.Toasts.LowerThanLastAppointment.Content"
          ),
          variant: "danger",
        },
      };

      const variant = types[type];
      this.showToast(
        variant.title,
        variant.icon,
        variant.text,
        variant.variant
      );
    },
    confirmAppointment() {
      const isFormValid = this.validateForm();

      if (!isFormValid) return;

      this.$emit("confirmAppointment", {
        ...this.data,
        datepicker: this.datepicker,
      });
    },
    showDatepicker() {
      this.datepicker.show = !this.datepicker.show;
    },
    handleCloseModal() {
      this.setData();
      this.$emit("close");
    },
    setFields() {
      const { title, isControlByHour, isMultipleOrders } = this;
      const isRework = Number(this.appointmentType) === 1;
      const partialFields = isControlByHour
        ? []
        : [isRework ? "rework" : "produced", "rejection", "reason"];

      const stopFields = ["status"];

      if (title === "Partial" && !isMultipleOrders) {
        return partialFields;
      }

      if (title === "Stop") {
        return stopFields;
      }

      return [];
    },
    returnDefaultDate(component = "") {
      // Para aguardar a chamada da API sem quebrar o mount
      const didFetchValues =
        !!this?.defaultDate?.start || !!this?.defaultDate?.setup;

      if (!component || !didFetchValues) return new Date();

      const card = this.title.toLowerCase();

      if (component === "datepicker") {
        if (!this.allowChangeDate || this.isMultipleOrders) return new Date();
        if (card === "start") {
          return this.defaultDate.start;
        }

        if (card === "setup") return this.defaultDate.setup;

        return new Date();
      }

      if (component === "input") {
        if (!this.allowChangeDate || this.isMultipleOrders) {
          return this.formatDatetime(new Date());
        }

        // Retorna formatação e acordo com o idioma
        if (card === "start") {
          return this.formatDatetime(this.defaultDate.start);
        }

        if (card === "setup") {
          return this.formatDatetime(this.defaultDate.setup);
        }

        return this.formatDatetime(new Date());
      }
    },
    setData() {
      const setValueProduced = () => {
        if (this.isControlByHour) return null;
        return this.shouldFillDefaultValues ? this?.defaultProducedQuantity : 0;
      };

      this.datepicker = this.returnDefaultDate("datepicker") ?? new Date();
      this.data = {
        resource: {
          title: "Resource",
          value: this?.defaultResource?.text
            ? this?.resourcesList?.find(
                (item) => item?.value == this?.defaultResource?.value
              )?.text
            : null,
          disabled: !this?.allowChangeResource && this?.defaultResource,
          alert: !this?.defaultResource?.value ?? !this?.data?.resource?.id,
          action: this.handleSelectModal,
          id: Number(this?.defaultResource?.value) ?? null,
        },
        date: {
          title: "Date",
          value: this.returnDefaultDate("input"),
          disabled: !this?.allowChangeDate,
          alert: false,
          action: () => {
            // this.openDatepicker();
          },
        },
        produced: {
          title: "Produced",
          value: setValueProduced(),
          disabled: this.isMultipleOrders || this.isControlByHour,
          alert: true,
          validations: {
            validateModalInputFields: this.validateModalInputFields,
            validateProducedRemaining: this.validateProducedRemaining,
          },
          action: this.handleInputModal,
        },
        rework: {
          title: "Rework",
          value: this.isControlByHour ? null : 0,
          disabled: this.isMultipleOrders || this.isControlByHour,
          alert: true,
          validations: {
            validateModalInputFields: this.validateModalInputFields,
          },
          action: this.handleInputModal,
        },
        rejection: {
          title: "Rejection",
          value: this.isControlByHour ? null : 0,
          disabled: this.isMultipleOrders || this.isControlByHour,
          alert: true,
          validations: {
            validateModalInputFields: this.validateModalInputFields,
            validateRejectionInputModal: this.validateRejectionInputModal,
          },
          action: this.handleInputModal,
        },
        reason: {
          title: "Reason",
          value: this.isControlByHour ? null : "",
          disabled:
            this.isMultipleOrders ||
            this.isControlByHour ||
            !this?.data?.rejection?.value,
          alert: true,
          action: this.handleSelectModal,
        },
        status: {
          title: "Status",
          value: "",
          disabled: false,
          alert: true,
          action: this.handleSelectModal,
        },
      };

      if (this.isMultipleOrders) this.setDataInMultipleAppointment();
      if (this.data.produced) this.validateModalInputFields();
    },
    setDataInMultipleAppointment() {
      const {
        formatDatetime,
        allowChangeDate,
        isMultipleOrders,
        data: { date },
      } = this;

      if (!isMultipleOrders) return;

      this.data.date = {
        ...date,
        value: formatDatetime(new Date()),
        disabled: !allowChangeDate,
      };
    },
    closeAllModals() {
      this.inputModal = false;
      this.selectModal.open = false;
    },
    handleInputModal(field, action = "open") {
      const props = action === "open" && {
        title: field,
        quantityProp: this.data[field.toLowerCase()].value,
      };

      this.selectedFieldInputModal = action === "open" ? props : {};
      this.inputModal = action === "open";
    },
    handleSelectModal(field, action = "open") {
      this.selectModal.open = !!(action === "open");
      this.selectModal.title = field;
      this.loadModalOptions(field);
    },
    loadModalOptions(field) {
      const formatListItems = (list) =>
        list.map((item) => ({ name: item?.text, id: item.value }));

      if (field === "Status") {
        this.selectModal.data = formatListItems(this.statusList);
      } else if (field === "Reason") {
        this.selectModal.data = formatListItems(this.rejectionList);
      } else if (field === "Resource") {
        this.selectModal.data = formatListItems(this.resourcesList);
      }

      this.selectModal.selectedId =
        this?.data[String(field).toLowerCase()]?.id ?? null;
    },
    handleSelectModalClick(value) {
      const field = String(value.title).toLowerCase();
      this.selectModal.selectedId = value?.item.id;
      this.data[field].value = value.item.name;
      this.data[field].id = value.item.id;
      this.data[field].alert = false;
    },
    validateModalInputFields() {
      const keys = ["produced", "rejection", "rework"];
      const validatations = keys.map((key) => this.data[key].value !== 0);
      const validadation = !validatations.includes(true);

      for (const key of keys) this.data[key].alert = validadation;
    },
    validateProducedRemaining() {
      const {
        produced: { value },
      } = this.data;

      if (value < 0) this.data.produced.value = 0;
    },
    validateRejectionInputModal() {
      const {
        rejection: { value },
      } = this.data;

      this.data.rejection.disabled = !value;
      this.data.rejection.alert = !value;
    },
    showSetupAlert() {
      // Somente caso exista algum apontamento anterior
      const order = this?.orderObject;
      const hasPreviousAppointment = this?.isControlByHour
        ? !!order?.last_event_time_by_user
        : !!order?.last_event_time;
      const isSetupModal = this.title === "Setup";
      return hasPreviousAppointment && isSetupModal;
    },
    setValueInputModal(context) {
      const { title, quantity } = context;
      this.data[title.toLowerCase()].value = quantity;

      const partialCardFields = ["produced", "rework", "rejection"];
      const fieldFromPartialCard = partialCardFields.includes(
        title.toLowerCase()
      );

      // No card Parcial, um dos campos (produzido, retrabalho, refugo) é obrigatório ser preenchido.
      if (fieldFromPartialCard) {
        const isAnyFieldFilled = partialCardFields.some(
          (field) => !!this.data[field].value
        );

        for (const field of partialCardFields) {
          if (isAnyFieldFilled) {
            this.data[field].alert = false;
          } else {
            this.data[field].alert = true;
          }
        }

        // Campo Motivo de Refugo é obrigatório caso campo Refugo esteja preenchido
        const isRejectionField = title.toLowerCase() === "rejection";
        if (isRejectionField && !!quantity) {
          this.data.reason.disabled = false;
          this.data.reason.alert = true;
        } else if (isRejectionField && !quantity) {
          this.data.reason.disabled = true;
          this.data.reason.alert = false;
        }
      }
    },
  },
};
</script>

<style>
.appointment-leave-active {
  transition: opacity 0.25s ease-in-out;
}

.appointment-leave {
  opacity: 100%;
}

.appointment-leave-to {
  opacity: 0%;
}

body {
  touch-action: manipulation;
}
</style>

<style lang="scss" scoped>
.appointment-modal-container {
  overflow: auto;
  height: 100dvh;
  width: 100vw;
  background: #fcfcfc;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cfc4be;
    border-radius: 9px;
  }

  .fields-container {
    padding: 24px 29px calc(24px + 63px + 24px);
    display: flex;
    flex-grow: 1;

    .fields-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;

      .fields-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-auto-rows: 124px;
        grid-auto-flow: dense;
        gap: 16px 30px;

        .grid-full {
          grid-column: span 2;
        }
      }

      .apm__button-c {
        width: 100%;
        padding: 0 24px;
        position: fixed;
        bottom: 24px;
        left: 0;

        button {
          margin: 0 auto;
        }
      }
    }
  }
}

@media (max-width: 768px) and (min-width: 425px) {
  .appointment-modal-container {
    .fields-container {
      .fields-wrapper {
        .fields-list {
          grid-template-columns: minmax(0, 1fr) !important;
          grid-auto-rows: 138px !important;

          .grid-full {
            grid-column: inherit !important;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .appointment-modal-container {
    .fields-container {
      padding: 24px 16px calc(24px + 36px + 16px) !important;

      .fields-wrapper {
        .fields-list {
          grid-template-columns: minmax(0, 1fr) !important;
          grid-auto-rows: 92px !important;

          .grid-full {
            grid-column: inherit !important;
          }
        }

        .apm__button-c {
          padding: 0 16px !important;
          bottom: 16px !important;
        }
      }
    }
  }
}
</style>
